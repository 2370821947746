










































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import type { MetaInfo } from 'vue-meta';

import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import type { CustomerOrder, CustomerOrders } from '~/modules/GraphQL/types';

import type { OrderStatusCode, OrderTabsId } from '../../../types';

export default defineComponent({
  name: 'AccountOrders',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoHelpBlock: () => import('organisms/VaimoHelpBlock.vue'),
    AccountOrder: () =>
      import('organisms/account/parts/order/AccountOrder.vue'),
    SfLoader
  },
  setup() {
    const { search } = useUserOrder();
    const { isDesktop } = useScreenSize();
    const orders = ref<CustomerOrder[]>([]);
    const rawCustomerOrders = ref<CustomerOrders | null>(null);
    const activeTabId = ref<OrderTabsId>('all');
    const isOpenId = ref([]);
    const nav = ref(null);
    const tabRefs = ref(null);
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const orderStatuses: Record<OrderStatusCode, string | string[]> = {
      complete: 'complete',
      canceled: ['canceled', 'rejected'],
      closed: 'closed'
    };

    const sortOrdersByDate = (orders: CustomerOrder[] | []) => {
      return (
        orders?.sort(
          (a, b) =>
            new Date(b.order_date).getTime() - new Date(a.order_date).getTime()
        ) ?? []
      );
    };

    useFetch(async () => {
      rawCustomerOrders.value = await search({
        filter: {
          status: {
            nin: [
              'payment_review',
              'pending',
              'pending_payment',
              'payment_authorized',
              'warehouse_sent',
              'warehouse_ready'
            ]
          }
        },
        currentPage: 1,
        pageSize: 100,
        customQuery: {
          customerOrders: 'customerOrdersWithProductImg'
        }
      });

      orders.value = sortOrdersByDate(rawCustomerOrders.value?.items);
    });

    const getOrdersByStatus = (status: OrderStatusCode | string | string[]) => {
      if (Array.isArray(status)) {
        return orders.value.filter((obj) => status.includes(obj.status_code));
      }
      return orders.value.filter((obj) => obj.status_code === status);
    };

    const tabsNav = computed(() => [
      {
        id: 'all',
        title: 'See everything',
        orders: orders.value
      },
      {
        id: 'processing',
        title: 'Processing',
        orders: orders.value.filter(
          (obj) =>
            !Object.values(orderStatuses).flat().includes(obj.status_code)
        )
      },
      {
        id: 'complete',
        title: 'Completed',
        orders: getOrdersByStatus(orderStatuses.complete)
      },
      {
        id: 'canceled',
        title: 'Cancelled',
        orders: getOrdersByStatus(orderStatuses.canceled)
      },
      {
        id: 'closed',
        title: 'Refunded',
        orders: getOrdersByStatus(orderStatuses.closed)
      }
    ]);

    const activeOrders = computed<CustomerOrder[] | []>(
      () =>
        tabsNav.value.find((tab) => tab.id === activeTabId.value)?.orders || []
    );

    const setActiveTabId = (tabId: OrderTabsId) => {
      activeTabId.value = tabId;
      isOpenId.value = [];
    };

    const changeBlockVisibility = (orderId) => {
      if (isOpenId.value.includes(orderId)) {
        isOpenId.value.splice(isOpenId.value.indexOf(orderId), 1);
      } else {
        isOpenId.value.push(orderId);
      }
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      nav.value.scrollTop = pos.top - dy;
      nav.value.scrollLeft = pos.left - dx;
    };
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      nav.value.style.cursor = 'grab';
      nav.value.style.removeProperty('user-select');
    };

    const mouseDownHandler = (event) => {
      if (!isDesktop) {
        return;
      }

      nav.value.style.cursor = 'grabbing';
      pos = {
        // The current scroll
        left: nav.value.scrollLeft,
        top: nav.value.scrollTop,
        // Get the current mouse position
        x: event.clientX,
        y: event.clientY
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    // clean it up
    onBeforeUnmount(() => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    });

    const handleKeyDown = (event) => {
      const index = tabsNav.value.findIndex(
        (tab) => tab.id === activeTabId.value
      );
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        const newIndex = event.key === 'ArrowLeft' ? index - 1 : index + 1;
        if (tabsNav.value[newIndex]) {
          setActiveTabId(tabsNav.value[newIndex].id as OrderTabsId);
          tabRefs.value[newIndex]?.focus();
        }
      }
    };

    return {
      orders,
      rawCustomerOrders,
      tabsNav,
      activeTabId,
      setActiveTabId,
      nav,
      mouseDownHandler,
      isOpenId,
      changeBlockVisibility,
      handleKeyDown,
      tabRefs,
      activeOrders
    };
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  }
});
